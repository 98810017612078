import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import FAQAccordion from "../components/faqAccordion";
import useModalState from '../helpers/modalState';

import * as styleSheet from "./service.module.scss"
import FixedImage from "../components/fixedImage";

const ServiceTemplate = (props) => {

  const [showAppointmentModal, setShowAppointmentModal] = useModalState();

  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({modalOpen: true});
		}
  }
  function handleApptKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
			setShowAppointmentModal({modalOpen: true});
		}
  }

	const {
		data: {
			service,
			service: {
				title,
				ACFService: {
					serviceInfo: {
						left: {
              blurb,
							requestAppointmentLink,
							faqs,
							callout: {
								backgroundImage: calloutBG,
								blurb: calloutBlurb,
								heading: calloutHeading,
								link: calloutLink
							}
						},
						right: {
              heroBackgroundImage,
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
              serviceContentTop: {
                textContent: serviceTextContentTop,
                steps: serviceContentTopSteps,
                appointmentLinkText: serviceContentTopApptLinkText
              },
							serviceContent: content
						}
					}
				}
			},
			home: {
				ACFHomePage: {
					hero: {
						callouts: {
							callout1: {
								heading: homeCalloutHeading,
								blurb: homeCalloutBlurb,
                makeAnAppointmentLinkText: homeApptLinkText,
							}
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							mobileNavigationLogo
						} 
					}
				}

			},
			rightArrowYellow,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			calloutBG: genericCalloutBG
		}
	} = props;

	return (
		<Layout>
			<Seo post={service} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.servicename}>{title}</h1>
					</div>
					<div className={styleSheet.leftbottom}>
            { blurb && <div className={styleSheet.serviceheading}>{blurb}</div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styleSheet.request} /> }
            { !requestAppointmentLink && homeApptLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.request} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{homeApptLinkText}</button> }
					</div>
				</div>
				<div className={styleSheet.right}>
		      {heroBackgroundImage && <FluidImage image={heroBackgroundImage} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
          {heroBackgroundImage && <FluidImage image={heroBackgroundImage} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
          {heroBackgroundImage && <FluidImage image={heroBackgroundImage} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
          {heroBackgroundImage && <FluidImage image={heroBackgroundImage} passedClass={styleSheet.bgxl} loading={"eager"} /> }
          
          { !heroBackgroundImage && introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
          { !heroBackgroundImage && introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ !heroBackgroundImage && introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ !heroBackgroundImage && introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }

					{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.servicename} dangerouslySetInnerHTML={{__html: title}}></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
					{ !introLink && homeApptLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{homeApptLinkText} <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					{ faqs && <FAQAccordion faqs={faqs} /> }
					<div className={styleSheet.callout}>
						<div className={styleSheet.left}>
							{ (genericCalloutBG || calloutBG) && <FluidImage image={calloutBG ? calloutBG : genericCalloutBG} passedClass={styleSheet.bg} /> }
						</div>
						<div className={styleSheet.right}>
							{ (calloutHeading || homeCalloutHeading) && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading ? calloutHeading : homeCalloutHeading }}></div> }
							{ (calloutBlurb || homeCalloutBlurb) && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: calloutBlurb ? calloutBlurb : homeCalloutBlurb}}></div> }
							{ calloutLink && <WPLink link={calloutLink} passedClass={styleSheet.link} /> }
							{ !calloutLink && homeApptLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{homeApptLinkText}</button> }
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.servicecontent}>
						<div className={styleSheet.contentleft}>
              {(serviceTextContentTop || serviceContentTopSteps) && <div className={styleSheet.servicecontenttop}>
                { serviceTextContentTop && <div className="wpcontent" dangerouslySetInnerHTML={{__html: serviceTextContentTop}}></div> }
                {serviceContentTopSteps && <ol className={styleSheet.servicesteps}>
                  {serviceContentTopSteps.map((step, _index) => {
                    return <li key={'step'+step.stepTitle[0] + _index}>
                        <h3 className={styleSheet.title}>{step.stepTitle}</h3>
                        <p>{step.stepText}</p>
                      </li>
                  })}
                </ol>}
                {serviceContentTopApptLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.request} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{homeApptLinkText}</button>}
              </div>}

							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  service: wpService(id: {eq: $id}) {
    id
    title
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFService {
      serviceInfo {
        left {
          blurb
          requestAppointmentLink {
            title
            url
            target
          }
          faqs {
            ... on WpFaq {
              id
              ACFFaq {
                faqAnswer
              }
              title
            }
          }
          callout {
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
                publicURL
                extension
              }
            }
            blurb
            heading
            link {
              target
              title
              url
            }
          }
        }
        right {
          heroBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 840, layout: CONSTRAINED)
              }
            }
          }
          introContent {
            blurb
            heading
            link {
              target
              title
              url
            }
          }
          serviceContentTop {
            textContent
            steps {
              stepTitle
              stepText
            }
            appointmentLinkText
          }
          serviceContent
        }
      }
    }
  }
  home: wpPage(isFrontPage: {eq: true}) {
    ACFHomePage {
      hero {
        callouts {
          callout1 {
            heading
            blurb
            makeAnAppointmentLinkText
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  teamPage: allWpPage(filter: {template: {templateName: {eq: "Team Page"}}}) {
    nodes {
      link
    }
  }
  introBGMobile: file(name: {eq: "rdb_hero_fade4"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "rdb_hero_fade4"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "rdb_hero_fade4"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "rdb_hero_fade4"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  calloutBG: file(name: {eq: "callout_overlay_girl"}) {
    childImageSharp {
      gatsbyImageData(width: 295, layout: CONSTRAINED)
    }
  }
}
`;

export default ServiceTemplate;